.footer
  background-color #212121
  color #fff
  padding 60px 0
  +above('tablet')
    padding 60px 0 80px 0

  &__wrapper
    content-container(30px)
    max-width 1000px
    display flex
    justify-content space-around
    flex-wrap wrap
    +above('tablet')
      justify-content space-between

  &__cta
    padding 5px 10px
    border 1px solid white
    background-color white
    color #212121
    text-transform uppercase
    cursor default
    font-weight 700
    font-size 1.1rem
    
    &:hover, &:active, &:focus
      background-color rgba(white, 0.9)
      color black
      transition .2s cubic-bezier(0.4, 0, 0.2, 0.99);
      
    &:focus
      text-decoration underline

  &__section
    min-width 175px
    padding-bottom 25px

    &-title
      display block
      text-transform uppercase
      padding-bottom 1px
      margin-bottom 12px
      margin-right 5px
      border-bottom 3px solid rgba(white,0.2)
      font-weight 700
      font-size 1.1rem
      color rgba(white, 0.7)


    &-link
      display block
      text-decoration none
      font-size 0.9rem
      font-weight 300
      line-height 2rem
      transition .1s cubic-bezier(0.4, 0, 0.2, 0.99);
      &, &:visited
        color #b9b9b9
        color rgba(white, 0.9)
      &:hover, &:focus
        color #fff
