.panel
  padding 40px 30px
  +above('tablet')
    padding 32px 40px
  +above('desktop')
    padding 60px 40px
  background-color paleBlue

  &__container
    max-width maxWidthContent
    margin 0 auto
    display block
    +above('desktop')
      display flex

  &__headline
    max-width maxWidthContent
    margin 0 auto
    font-size 32px
    line-height 1.4
    padding-bottom 16px
    +above('tablet')
      padding-bottom 16px
      font-size 40px
      
    &--small
      font-size 20px
      margin-top 40px

  &__content-block
    width 100%

  &__paragraph
    font-size 16px
    line-height 1.6
    +above('tablet')
      font-size 18px

  &__image-block
    flex-shrink 0
    flex-grow 1
    max-width 300px
    margin 0 auto
    +above('desktop')
      margin 0
      max-width 350px
      &--right
        margin-left 35px
      &--left
        order -1
        margin-right 35px

  &__image-view
    padding 8px 0
