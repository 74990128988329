.header
  background-position: center
  background-size: cover
  background-color: #dfb95d
  background-image: url(../img/header-tofino@desktop.jpg)
  width: 100%
  height: 75vh
  min-height: 550px
  position: relative
  margin: 0
  padding: 0
  overflow: hidden
  
  &--condensed
    height: 50vh
    min-height 320px
  
  &--handpan
    background-image: url(../img/lukas-handpan-orangerie@desktop.jpg)
    &:before
      content: ""
      color: #ff0000
      display: block
      position: absolute
      width: 100%
      height: 100px
      top: 0px
      background: url(../img/header-fade-top.png) repeat-x bottom
      opacity: 0.6
    
  &:after
    content: ""
    color: #ff0000
    display: block
    position: absolute
    width: 100%
    height: 100px
    bottom: 0px
    background: url(../img/header-fade.png) repeat-x bottom
    opacity: 0.6

  &__content
    position relative
    display flex
    align-items center
    height 100%
    max-height 110vh
    content-container()

  &__title
    position absolute
    top 10px
    left 15px
    +above('tablet')
      left 25px

  &__title a
    text-decoration: none
    color: #fff
    font-weight: 100

  &__slogan
    width 95%
    margin 25vh auto
    padding 0 15px
    max-width maxWidthContent
    text-align center
    border 7px solid #fff
    font-size 40px
    font-weight 700
    text-transform uppercase
    text-shadow rgba(0, 0, 0, 0.3) 0px 1px 2px
    color white
    +above('tablet')
      font-size: 55px
      margin: 20vh auto
    +above('desktop')
      font-size: 64px
      margin: 25vh auto

    &-link
      &:hover,
      &:visited,
      &:active,
      &:link
        color: #fff
      &:hover
        text-decoration none
