@require "rupture"
rupture.scale = 0 640px 1024px
rupture.scale-names = 'mobile' 'tablet' 'desktop'

// general mixins
content-container(padding = 15px)
  max-width 1200px
  margin 0 auto
  padding 0 padding

// base variables
paleBlue = #f1f7f7
sharkGrey = #27272b
maxWidthContent = 1000px

headlineFont = Montserrat, "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif
bodyFont = "Open Sans", "museo-sans", sans-serif

// base styles
body
  font-family bodyFont
  font-weight 300
  overflow-y scroll
  -webkit-overflow-scrolling touch

p
  font-weight 300

a.link
  &
  &:visited
  &:hover
  &:focus
  &:active
    color sharkGrey
    font-weight 400
    text-decoration none
    border-bottom 2px solid rgba(0,15,30,.2)
    transition .2s cubic-bezier(0.4, 0, 0.2, 0.99);
  &:hover
    border-bottom 2px solid rgba(0,15,30, 1)

a.cta
  padding 12px 20px
  border 1px solid #212121
  border-radius 32px
  background-color #212121
  color white
  cursor pointer
  font-weight 700
  font-size 1.1rem
  
  &:hover, &:active, &:focus
    background-color rgba(#212121, 0.9)
    transition .2s cubic-bezier(0.4, 0, 0.2, 0.99);
    
  &:focus
    text-decoration underline

// Legacy code
.full-width
  width: 100%
  margin-left: auto
  margin-right: auto
  max-width: initial

.row-container
  margin: 0 auto
  max-width: 1000px
  height: 100px

h1, h2, h3
  font-family headlineFont

h3
  font-weight: 300
  font-size: 2.5em

h4
  color: dimgrey
  font-weight: 200
  font-size: 1.5em

.row-bright
  background-color: #f1f7f7
  padding 25px 20px
  +above('tablet')
    padding 4em 0 3em 0

.row-dark
  background-color: #160c00
  padding: 2em 0 1em 0
  color: #fff

.row-caption
  background-color: #f1f7f7
  padding: 4.7em 0 3.5em 0
  text-align: center

.row-inverse
  background-color: #1c1c1c
  padding: 4.7em 0 3.5em 0
  color: #fff
  text-align: center

.row-inverse p
  font-size: 1.5em
  font-weight: 200

.row-bg
  background-size: cover

.row-teaser
  padding 0 20px

.row-teaser p
  margin: 0.5em 0 0.8em 0
  text-align: center
  font-size: 2.3em
  font-weight: 100

// Module imports
@require "header"
@require "panel"
@require "panel-list"
@require "footer"
