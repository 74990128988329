.panel-list
  list-style none
  font-size 20px
  font-weight 200
  text-align center
  margin 32px 0

  &--left-aligned_desktop
    +at('desktop')
      text-align left
      
  &__item
    margin 24px 0
